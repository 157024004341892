import React, { useState, useEffect, useRef } from "react";
import { useStaticQuery, graphql, Link, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Fade } from "react-awesome-reveal";
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { transitionLink } from '../const/attr'

import { labels, localeUrls, badgesLocale } from '../langs/langs'
import { checkForSlug, isBrowser } from '../functions/functions'

import IsometricMapSVG0 from "./IsometricMapSVG_0";
import IsometricMapSVG1 from "./IsometricMapSVG_1";

const IsometricMap = ({ localeID, data }) => {

    const [floor, setFloor] = useState(0);
    const [store, setStore] = useState('A47');

    const farmImg = useStaticQuery(graphql`
        query FarmImgQuery {
            file(relativePath: { eq: "farmers-logo.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                    )
                }
            }
        }
    `)

    const stores = useRef({
        ...dataByPosition(data.nodes),
        'A47': {
            title: labels[localeID]["STORES_F"],
            acfStoresData: {
                subtitle: labels[localeID]["FARM_MARKE_SUB"],
                logosquare: {localFile:{childrenImageSharp:[{...(farmImg.file.childImageSharp)}]}}
            },
            categories: {
                nodes: [{slug: localeUrls[localeID]["STORES_F"]}]
            },
            slug: localeUrls[localeID]["STORES_F"],
            tags: {nodes: []}
        }
    });

    const onStoreClick = (position) => {
        if (stores.current.hasOwnProperty(position) && isBrowser()) {
            document.getElementById('clickStore').click()
        }
    }
    const onMouseEnter = (position) => {
        if (stores.current.hasOwnProperty(position)) {
            setStore(position)
        }
    }
    const onMouseLeave = () => {
        //setStore('dummy')
    }

    return (
        <>
            <div id={localeUrls[localeID]["MAP_ID"]}></div>
            <div id="isometricMap" className="hPadSec">
                <div className="content">
                    {store !== 'dummy' &&
                        <>
                            <div>
                                <div className="store">
                                    <AniLink {...transitionLink} key={store} to={localeUrls[localeID]["STORES"] + '/' + stores.current[store].slug}><GatsbyImage image={stores.current[store]?.acfStoresData?.logosquare?.localFile?.childrenImageSharp[0]?.gatsbyImageData} alt='test' /></AniLink>
                                    <div className="center l">
                                        <div>
                                            <h3><AniLink id="clickStore" {...transitionLink} to={localeUrls[localeID]["STORES"] + '/' + stores.current[store].slug}>{stores.current[store].title}</AniLink></h3>
                                            <p>{stores.current[store].acfStoresData.subtitle}</p>
                                        </div>
                                    </div>
                                </div>
                                {!!stores.current[store].tags.nodes.length && stores.current[store].tags.nodes.map((tag, indexT)=>{
                                    return <div key={indexT} className="tag">{tag.name}</div>
                                })}
                            </div>
                            <div className="badgeCon">
                                {checkForSlug(stores.current[store].categories.nodes, localeUrls[localeID]["STORES_F"]) && <AnchorLink to={localeUrls[localeID]["STORES"] + '#' + localeUrls[localeID]["STORES_F"]}><img src={badgesLocale[localeID]["mark"]}/></AnchorLink>}
                                {checkForSlug(stores.current[store].categories.nodes, localeUrls[localeID]["STORES_E"]) && <AnchorLink to={localeUrls[localeID]["STORES"] + '#' + localeUrls[localeID]["STORES_E"]}><img src={badgesLocale[localeID]["cuis"]}/></AnchorLink>}
                                {checkForSlug(stores.current[store].categories.nodes, localeUrls[localeID]["STORES_G"]) && <AnchorLink to={localeUrls[localeID]["STORES"] + '#' + localeUrls[localeID]["STORES_G"]}><img src={badgesLocale[localeID]["groc"]}/></AnchorLink>}
                                {checkForSlug(stores.current[store].categories.nodes, localeUrls[localeID]["STORES_C"]) && <AnchorLink to={localeUrls[localeID]["STORES"] + '#' + localeUrls[localeID]["STORES_C"]}><img src={badgesLocale[localeID]["bars"]}/></AnchorLink>}
                            </div>
                        </>}
                    <div className="buttonsCon">
                        <Fade>
                            <button className={"tag button " + (floor ? "" : "clicked")} onClick={()=>setFloor(0)}>{labels[localeID]["GFLOOR"]}</button>
                            <button className={"tag button " + (floor ? "clicked" : "")} onClick={()=>setFloor(1)}>{labels[localeID]["FFLOOR"]}</button>
                        </Fade>
                    </div>
                    <IsometricMapSVG0 className={floor ? "hidden" : ""} onClickFunction={onStoreClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} clicked={store} stores={stores.current}/>
                    <IsometricMapSVG1 className={floor ? "" : "hidden"} onClickFunction={onStoreClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} clicked={store} stores={stores.current}/>
                </div>
            </div> 
        </>   
    )
}

export default IsometricMap;

function dataByPosition (data) {
    if (!data) return;
    const result = {};
    data.map((item)=>{
        result[item.acfStoresData.position] = item;
    })
    return result;
}
